var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-list",
    { attrs: { dark: "", nav: "" } },
    [
      _c(
        "v-list-item-group",
        {
          staticClass: "v-item-group--black",
          attrs: { "active-class": "primary white--text" },
        },
        _vm._l(_vm.items, function (item) {
          return _c(
            "v-list-item",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: item.link != "",
                  expression: "item.link != ''",
                },
              ],
              key: item.index,
              staticClass: "mb-1",
              attrs: { router: "", to: { name: item.link } },
            },
            [
              _c(
                "v-list-item-content",
                [
                  _c(
                    "v-list-item-title",
                    { staticClass: "font-weight-medium" },
                    [_vm._v(" " + _vm._s(_vm.$t(item.title)) + " ")]
                  ),
                ],
                1
              ),
              _c(
                "v-list-item-action",
                [_c("v-icon", [_vm._v(_vm._s(item.icon))])],
                1
              ),
            ],
            1
          )
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }