<template>
    <v-list dark nav>
        <v-list-item-group class="v-item-group--black" active-class="primary white--text">
            <v-list-item v-for="item in items"
                         v-show="item.link != ''"
                         :key="item.index"
                         router
                         class="mb-1"
                         :to="{name: item.link}">
                <v-list-item-content>
                    <v-list-item-title class="font-weight-medium">
                        {{ $t(item.title) }}
                    </v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                    <v-icon>{{ item.icon }}</v-icon>
                </v-list-item-action>
            </v-list-item>
        </v-list-item-group>
    </v-list>

</template>

<style scoped>
    a {
        text-decoration: none;
    }

    .v-item-group--black .theme--light.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled) {
        color: rgb(0,0,0) !important;
    }
</style>

<script>
    import Utilities from '@/Utilities.js'
    import { FormMixIn } from '@/MixIns/FormMixins';
    export default {
        mixins: [FormMixIn],
        data() {
            return {
                items: []
            };
        },
        methods: {
            close() {
                this.items.forEach(item => {
                    if (item.active) {
                        _.delay(function () {
                            item.active = false;
                        }, 300);
                        return false;
                    }
                });
            },
            MenuItemClick(item) {
                Utilities.log('menu clicked', item);
                var link = item.link;
                this.$router.push({ path: link });
            }
        },
        mounted: function () {
            Utilities.log('mount menu');
            this.$store
                .dispatch('GetAccordionMenu')
                .then(menu => {
                    Utilities.log('Got menu', menu);
                    this.items = menu;
                    if (!this.GetUserInfo.approved) {
                        this.items = this.items.filter(item => !this.IsApprovedPage(item.link));
                    }
                })
                .catch(error => {
                    this.ShowDialog(this.$root.$i18n.t('EM_UnexpectedErrorTryAgain'), 'E', 'dialog');
                });
        }
    };
</script>
