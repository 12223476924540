var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      !_vm.loading
        ? _c(
            "v-main",
            { staticClass: "mt-n6", attrs: { fluid: "" } },
            [
              _c(
                "v-container",
                { staticClass: "pa-8" },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: !_vm.tedarikciapproved,
                              expression: "!tedarikciapproved",
                            },
                          ],
                          attrs: { cols: "12", md: "12" },
                        },
                        [
                          _c(
                            "v-alert",
                            {
                              staticClass: "mb-0 pl-6 v-alert--dark",
                              attrs: {
                                prominent: "",
                                border: "left",
                                dense: "",
                                rounded: "",
                                type: "warning",
                              },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("IM_CandidateDupplier")) +
                                  " "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    _vm._l(_vm.items, function (item) {
                      return (item.link == "tedarik" &&
                        !_vm.tedarikciapproved) ||
                        (item.whatToShow != "noList" && _vm.tedarikciapproved)
                        ? _c(
                            "v-col",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: !_vm.isHomePage(item.link),
                                  expression: "!isHomePage(item.link)",
                                },
                              ],
                              key: item.index,
                              attrs: { cols: "12", md: "4" },
                            },
                            [
                              _c(
                                "v-card",
                                {
                                  staticClass: "v-card--material--has-heading",
                                  staticStyle: { "margin-top": "5px" },
                                },
                                [
                                  _c(
                                    "v-card-title",
                                    { staticClass: "justify-end" },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "text-start v-card--material__heading mb-n6 v-sheet theme--dark elevation-6 pa-1 primary",
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(_vm.$t(item.title)) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "v-card-text",
                                    { staticClass: "text-left" },
                                    [
                                      item.innerItems.length == 0
                                        ? _c(
                                            "v-btn",
                                            {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value:
                                                    item.link == "tedarik" ||
                                                    _vm.tedarikciapproved,
                                                  expression:
                                                    "item.link == 'tedarik' || tedarikciapproved",
                                                },
                                              ],
                                              attrs: {
                                                tile: "",
                                                text: "",
                                                to: { name: item.link },
                                                color: "secondary",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t("AL_GoToPage")
                                                  ) +
                                                  " "
                                              ),
                                              _c(
                                                "v-icon",
                                                { attrs: { right: "" } },
                                                [_vm._v("mdi-arrow-right-bold")]
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      item.innerItems.length > 0
                                        ? _c("pieChart", {
                                            attrs: {
                                              dataItems: item.innerItems,
                                              menuItem: item,
                                            },
                                          })
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e()
                    }),
                    1
                  ),
                  _c(
                    "v-row",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.tedarikciapproved,
                          expression: "tedarikciapproved",
                        },
                      ],
                    },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "6", md: "6" } },
                        [_c("teklifList")],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _c(
            "v-main",
            { staticClass: "mt-n6", attrs: { fluid: "" } },
            [
              _c(
                "v-container",
                { staticClass: "pa-8" },
                [
                  _c(
                    "v-row",
                    _vm._l(10, function (i) {
                      return _c(
                        "v-col",
                        { key: i, attrs: { cols: "12", md: "6" } },
                        [
                          _c(
                            "v-alert",
                            {
                              staticClass: "mb-0 pl-6 v-alert--white",
                              staticStyle: { "background-color": "white" },
                              attrs: {
                                icon: "a",
                                prominent: "",
                                border: "left",
                                rounded: "",
                                "colored-border": "",
                                depressed: "",
                                color: "primary",
                              },
                            },
                            [
                              _c(
                                "v-row",
                                { attrs: { align: "center" } },
                                [
                                  _c(
                                    "v-col",
                                    { staticClass: "grow secondary--text" },
                                    [
                                      _c("v-skeleton-loader", {
                                        staticClass: "mr-auto",
                                        attrs: {
                                          "max-height": "20",
                                          "max-width": "360",
                                          type: "image",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    { attrs: { align: "right" } },
                                    [
                                      _c("v-skeleton-loader", {
                                        staticClass: "ml-auto",
                                        attrs: {
                                          "max-height": "20",
                                          "max-width": "120",
                                          type: "image",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }