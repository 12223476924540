var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.data.length != 0
    ? _c(
        "v-container",
        { attrs: { fluid: "" } },
        [
          _c("grid", {
            attrs: {
              columns: _vm.GetColumns(),
              data: _vm.GetDataItems(),
              attributes: _vm.GetFormsAttributes,
              addrow: "",
              showExportToExcelButton: false,
              clickrow: 1 == 1,
              HideHeader: true,
              itemsPerPageProp: 5,
            },
            on: {
              rowclicked: function ($event) {
                return _vm.RowClicked($event)
              },
              RowButtonClicked: function ($event) {
                return _vm.RowButtonClicked($event)
              },
            },
          }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }